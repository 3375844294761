<template>
  <div>
    <div class="card mb-3" style="width: 100%">
      <!-- <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Signature Information</h3>
      </div> -->
      <div class="signature-preview p-4" v-if="showPreview">
        <el-button type="danger" class="p-2" plain @click="showPreview = false;activeName='letter'"
          ><i class="el-icon-edit"></i> Edit</el-button
        >
        <div class="el-upload" v-if="getCurrentUserSign">
          <img :src="getCurrentUserSign" class="img-fluid" />
        </div>
      </div>
      <div class="signature-box" v-loading="verificationLoading" v-else>
        <el-tabs v-model="activeName" class="mt-2">

                   <el-tab-pane label="Text Signature" name="letter">
            <h4 class="font-weight-normal mb-1" v-if="getCurrentUserSign">Current Signature</h4>
            <div class="el-upload container">
              <img :src="getCurrentUserSign"  class="img-fluid" />
            </div>
            <el-form>
              <el-form-item class="mb-05" label="Select Style">
                <el-select
                  v-model="value"
                  placeholder="Select"
                  @change="onChangeStyle"
                >
                  <el-option
                    v-for="item in outlet"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option
                ></el-select>
              </el-form-item>
              <el-form-item label="Signature Text">
                <el-input
                  type="text"
                  v-model="signatureText"
                  label="Signature"
                  placeholder="Enter signature"
                ></el-input>

                <Div
                  class="fontsize"
                  :style="{ 'font-family': tstyle, color: this.signatureColor, 'max-width': '100%' }"
                >
                  {{ this.signatureText }} 
                </Div>
              </el-form-item>
            </el-form>
            <div class="my-3">
              <button
                type="button"
                class="color-btn red"
                @click="signatureColor = '#FF0000'"
              ></button>
              <button
                type="button"
                class="color-btn black"
                @click="signatureColor = '#000000'"
              ></button>
              <button
                type="button"
                class="color-btn green"
                @click="signatureColor = '#008000'"
              ></button>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Draw" name="drawer">
            <el-row>
              <el-col :span="24" v-loading="signatureLoading">
                <h4 class="font-weight-normal mb-1" v-if="getCurrentUserSign">Current Signature</h4>
                <!-- <div class="el-upload" v-if="getCurrentUserSign">
                  <img :src="getCurrentUserSign" height="200px" width="100%"/> -->
                <div class="el-upload">
                  <img :src="getCurrentUserSign" height="60px" width="100%"/>
                </div>

                <h4 class="font-weight-normal mb-1">
                  {{
                    getCurrentUserSign
                      ? "Update New Signature Here"
                      : "Add New Signature Here"
                  }}

                </h4>
                <VueSignaturePad
                class="sign-image"
                  id="signature"
                 height="75px"
                  width="498px"
                  ref="signaturePad"
                  :options="{ penColor,
                    onBegin: () => {
                      $refs.signaturePad.resizeCanvas();
                    },
                  }"
                />
              </el-col>
              <el-col :span="24">
                <el-button
                  class="float-right"
                  icon="el-icon-refresh-left"
                  size="medium"
                  plain
                  round
                  type="danger"
                  title="Undo"
                  @click="undo"
                  >Undo</el-button
                >
                <button
                  type="button"
                  class="color-btn red"
                  @click="setColor('red')"
                ></button>
                <button
                  type="button"
                  class="color-btn black"
                  @click="setColor('black')"
                ></button>
                <button
                  type="button"
                  class="color-btn green"
                  @click="setColor('green')"
                ></button>
              </el-col>
            </el-row>
          </el-tab-pane>

 
          <el-tab-pane label="Upload Signature" name="upload">
            <el-row>
              <el-col :span="24">
                <!-- <img
                  :src="signatureImage"
                  v-if="signatureImage"
                  class="img-fluid"
                /> -->
                <div>
                  <h4 class="font-weight-normal mb-1" v-if="getCurrentUserSign">Current Signature</h4>
                  <div class="el-upload">
                    <img :src="getCurrentUserSign" class="img-fluid" />
                  </div>
                </div>
                <h4 class="font-weight-normal mb-1">
                                    {{
                    getCurrentUserSign
                      ? "Update New Signature Here"
                      : "Add New Signature Here"
                  }}
                </h4>
                       <vue-anka-cropper 
                class="upload-file"
                :options="{
               aspectRatio: false,
               closeOnSave: true,
               cropArea: 'box',
               croppedHeight: 1000,
               croppedWidth: 1000,
               cropperHeight: false,
               dropareaMessage: ' Drag & Drop  file here or use the button below.',
             frameLineDash: [1,1000],
               frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
               handleFillColor: 'rgba(255, 255, 255, 0.2)',
               handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
               handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
               handleSize: 5,
               handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
               layoutBreakpoint: 40,
               maxCropperHeight: 1000,
               maxFileSize: 8000000,
               overlayFill: 'rgba(0, 0, 0, 0.5)',
               previewOnDrag: true,
               previewQuality: 0.65,
               resultQuality: 0.8,
               resultMimeType: 'image/jpeg',
               resultMimeType: 'image/png',
               resultMimeType: 'image/jpg',
                resultMimeType: 'image/gif',
               selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',
               
               showPreview: true,
               skin: 'light',
               uploadData: {},
               uploadTo: false}"         
              name="logo"
             @cropper-error="fileError"
            @cropper-preview="storeFileToUpload"
               @cropper-saved="someAction()"
               @cropper-file-selected="storeFileToUpload"
            ></vue-anka-cropper>
                <!-- <el-upload
                  class="avatar-uploader"
                  action
                  :on-change="storeFileToUpload"
                  :show-file-list="false"
                  :auto-upload="false"
                  accept="image/*"
                >
                  <div class="icon-block">
                    <div class="icon">
                      <img
                        src="@/assets/img/icons/upload-icon.svg"
                        alt="icon"
                      />
                    </div>
                    <div class="icon-text">
                      <p>Upload your signature here</p>
                    </div>
                  </div>
                </el-upload> -->
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div class="mb-0 p-2">
          <el-button type="danger" class="type-2" @click="saveCapturedSignature"
            >Save Change</el-button
          >
          <el-button @click="showPreview = true">Cancel</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import textToImage from "text-to-image";

import vueAnkaCropper from 'vue-anka-cropper'


import 'vue-anka-cropper/dist/VueAnkaCropper.css';
export default {
  name:"signature-MySignature",
  components: {vueAnkaCropper },
  data() {
    return {
      outlet: [
             {
          value: "AndyBold",
          label: "AndyBold",
        },
            {
          value: "ArialRoundedMTBold",
          label: "Arial Rounded MT Bold",
        },
         {
          value: "BickleyScript",
          label: "Bickley Script",
        },
         
         {
          value: "VladimirScript",
          label: "Vladimir Script",
        }, 
        
            {
          value: "KunstlerScript",
          label: "Kunstler Script",
        },
        
           {
          value: "Mistral",
          label: "Mistral",
        },
         

        {
          value: "Brush Script MT",
          label: "Brush Script MT",
        },{
          value: "Cookie",
          label: "Cookie",
        },
        {
          value:"Shears",
          label:"Shears",
        },
        {
          value:"Royaliya",
          label:"Royaliya",
        },
        {
          value:"Playfair Display",
          label:"Playfair Display",
        },
        {
          value:"Kaushan Script",
          label:"Kaushan Script",
        },
        {
          value:"Arizonia",
          label:"Arizonia",
        },
        {
          value:"Tangerine",
          label:"Tangerine",
        },
        {
          value: "Lucida Handwriting",
          label: "Lucida Handwriting",
        },
        {
          value: "Segoe Script",
          label: "Segoe Script",
        },
        // {
        //   value: "BRUSHSCI",
        //   label: "BRUSHSCI",
        // },
        {
          value: "FREESCPT",
          label: "FREESCPT",
        },
        // {
        //   value: "Gistesy",
        //   label: "Gistesy",
        // },
        {
          value: "NanumPenScript-Regular",
          label: "NanumPenScript-Regular",
        },
        {
          value: "NothingYouCouldDo-Regular",
          label: "NothingYouCouldDo-Regular",
        },
        {
          value: "RAGE",
          label: "RAGE",
        },
        // {
        //   value: "RockSalt",
        //   label: "RockSalt",
        // },
        // {
        //   value: "Sacramento-Regular",
        //   label: "Sacramento-Regular",
        // },
        {
          value: "SignPainterHouseScript",
          label: "SignPainterHouseScript",
        },
        {
          value: "KUNSTLER",
          label: "KUNSTLER",
        },
      ],
      value: "Tangerine",

      activeName: "letter",
      tstyle: "Tangerine",
      signatureText: "",
      signatureColor: "#000",
      penColor: "black",
      unlockSignaturePad: false,
      images: [],
      signatureImage: null,
      signatureLoading: false,
      userSignatureData: {},
      verificationLoading: false,
      showPreview: false,
      signatureType: false,
      authorizeSignatureFormData: {},
      getCurrentUserSign: null,
    };
  },
  props: [
    "selectedItem",
    "fromDocument"
  ],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getSignatureUpdateStatus",
      "getSignatureUpdateErrors",
    ]),
    // ...mapGetters("employeeData",["getUserSignature"])
  },
  async mounted() {
    if (this.getAuthenticatedUser && this.getAuthenticatedUser.signature) {
      this.getCurrentUserSign = this.getAuthenticatedUser.signature;
      this.showPreview = true;
    } else {
      await this.getCurrentUserSignature();
    }
  },
  watch: {
    signatureImage(){
    console.log("1")
      this.selectedItem.source = this.signatureImage;
    }
  },
  methods: {
    async getCurrentUserSignature() {
      await this.$store.dispatch("auth/fetchUserSignature");
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.signature) {
        this.getCurrentUserSign = this.getAuthenticatedUser.signature;
        this.showPreview = true;
      } else {
        this.showPreview = false;
      }
    },
    async onChangeStyle(font) {
      this.tstyle = font;
      console.log(this.tstyle);
    },
       async storeFileToUpload(file) {
     this.signatureImage  = file;
         setTimeout(() => {
        const doc = document.querySelector(".ankaCropper__previewArea");
        this.signatureImage = doc.querySelector("img").src;
        // console.log("selectedCropImageSrc", imageSrc);
      }, 1000);
    
    },
     fileError(){
    this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    // async storeFileToUpload(file) {
    //   this.getBase64(file.raw).then((res) => {
    //     this.signatureImage = res;
    //     //   console.log(res)
    //   });
    // },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let signature = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          signature = reader.result;
          // imgResult = reader.result;
          // console.log(signature);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(signature);
        };
      });
    },

    toggleSignatureText() {
      if (this.signatureType) {
        this.signatureText = "";
        this.signatureText += this.getAuthenticatedUser.first_name
          ? this.getAuthenticatedUser.first_name[0].toUpperCase()
          : "";
        this.signatureText += this.getAuthenticatedUser.last_name
          ? this.getAuthenticatedUser.last_name[0].toUpperCase()
          : "";
      }
    },
    async saveCapturedSignature() {
      try {
        let empty = true;
        if (this.activeName == "drawer") {
          if(this.$refs && this.$refs.signaturePad){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            empty = isEmpty;
            this.signatureImage = data;
          }else{
            this.signatureImage = this.getCurrentUserSign
            console.log("dataa", this.signatureImage)
          }
        }

        if (this.activeName == "letter" && this.signatureText) {
          await this.generateTextImage();
          empty = false;
        }

        if (this.activeName == "upload") {
          empty = false;
        }

        if (!empty) {
          this.verificationLoading = true;
          await this.$store.dispatch("auth/updateUserSignature", {
            signature: this.signatureImage,
          });
          await this.getCurrentUserSignature();
          this.verificationLoading = false;
        } else {
          this.$notify.success({
            title: "Success",
            message: "Signature saved successfully",
          });
          return;
        }

        if (this.getSignatureUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Signature updated successfully",
          });
          this.showPreview = true;
        } else {
          if (!this.getSignatureUpdateErrors) {
            this.$notify.error({
              title: "Error",
              message: "Error in updating signature",
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    generateTextImage() {
      // console.log('>>> generateTextImage() called');
      // let w = 50;
      let margin = 10;
    
      let fontSize = 30;
      let lineHeight= 30;
     // let customHeight = 80;
      if (this.tstyle == 'FREESCPT') {
        // w = 30;
      } else if (this.tstyle == 'NanumPenScript-Regular') {
        // w = 30;
      } else if (this.tstyle == 'NothingYouCouldDo-Regular') {
        // w = 40;
        // lineHeight = 50;
        fontSize = 25;
      } else if (this.tstyle == 'RAGE') {
        // w = 35;
        lineHeight = 35;
      } else if (this.tstyle == 'KUNSTLER') {
        // w = 25;
      } else if (this.tstyle == 'Brush Script MT') {
        // w = 45;
      } else if (this.tstyle == 'SignPainterHouseScript') {
        lineHeight = 35;
        // w = 30;
      } else if (this.tstyle == 'Segoe Script') {
        // w = 30;
        fontSize = 25;
        margin = 5;
      } else if (this.tstyle == 'Lucida Handwriting') {
        // w = 35;
        // lineHeight = 35;
        fontSize = 25;
      }
            else if (this.tstyle == "BickleyScript") {
        fontSize = 45;
      }
       else if (this.tstyle == "VladimirScript") {
        fontSize = 25;
      }
       else if (this.tstyle == "KunstlerScript") {
        fontSize = 25;
      }
     
       else if (this.tstyle == "Mistral") {
        fontSize = 25;
      }
      else if (this.tstyle == "Shears") {
       fontSize = 36;
      }
      else if (this.tstyle == "Royaliya") {
       fontSize = 36;
      }
      else if (this.tstyle == "Cookie") {
       fontSize = 36;
      }
      else if (this.tstyle == "Playfair Display") {
       fontSize = 24;
      }
      else if (this.tstyle == "Kaushan Script") {
       fontSize = 36;
      }
      else if (this.tstyle == "Arizonia") {
       fontSize = 36;
      }
        else if (this.tstyle == "Tangerine") {
       fontSize = 30;
      }
      // console.log('>>> generateTextImage(), this.signatureText.length, this.tstyle', this.signatureText.length, this.tstyle);
      // let imgWidth =
      //   this.signatureText.length * w < 300
      //     ? 300
      //     : this.signatureText.length * w;
      // console.log('>>> generateTextImage(), imgWidth', imgWidth);
      var maxWidth = 250;
      this.signatureText = this.signatureText.trim();

      if (this.signatureText.length >= 14) {
        maxWidth = maxWidth + (this.signatureText.length - 13) * 10 + 20;
        // lineHeight = lineHeight + (this.signatureText.length - 14) * 1;
      }
      // console.log('>>> generateTextImage(), maxWidth', maxWidth);

      textToImage
        .generate("  " + this.signatureText, {
          textColor: this.signatureColor,
          fontFamily: this.tstyle,
          maxWidth: maxWidth,
          textAlign: "left",
          verticalAlign: "center",
          fontSize: fontSize,
          // fontFamily: 'Arial',
          lineHeight: lineHeight,
         // customHeight:customHeight,
          margin: margin,
          bgColor: 'transparent',
          // textColor: 'red',
        })
        .then((dataUri) => {
          this.signatureImage = dataUri;
        });
    },

    setColor(color) {
      this.penColor = color;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
  },
};
</script>
<style lang="scss" scoped>
.ankaCropper__navigation  a:nth-child(4n+4) {
  display: none !important;
}
.ankaCropper__navigation  a:nth-child(5n+5) {
    display: none !important;
}
.ankaCropper__saveButton {
    display: none !important;
    padding: 8px 20px;
    font-size: 14px;
}
.fontsize {
  padding: 5%;
  font-size: 40px;
  max-width: 247px;
}
.el-image__error,
.el-image__inner,
.el-image__placeholder {
  width: unset !important;
}
.signature-box {
  display: block;
  max-width: 500px;
  padding: 0 1em;
  margin: 0 0 5px 0;
  .sign-image {
     border: 1px solid #dddddd;

    border-radius: 4px;
    margin-bottom: 3px;
    background-color: rgba(0, 0, 0, 0.025);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 80px;
      height: 1px;
      background-color: #999999;
      z-index: 0;
    }
  }
  .color-btn {
    height: 16px;
    width: 16px;
    margin-right: 1em;
    border-radius: 10px;
    border: 1px solid #efefef;
    &.red {
      background-color: #f34424;
    }
    &.blue {
      background-color: #333333;
    }
    &.black {
      background-color: #000;
    }
    &.green {
      background-color: #3d8116;
    }
  }
}
</style>

<style lang="scss">
.ankaCropper__saveButton {
    display: none !important;
    padding: 8px 20px;
    font-size: 14px;
}
.fontsize {
  padding: 5%;
  font-size: 40px;
  max-width: 247px;
}
.no-address-block {
  .image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #edeff5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    img {
      width: 50px;
    }
  }
  p {
    color: rgba(29, 28, 29, 0.4);
    line-height: 23px;
  }
  .el-upload {
    img {
      max-width: 150px;
    }
  }
  .el-button.is-circle {
    font-size: 10px !important;
  }
}
.preview-image {
  min-width: 30px;
}
.el-image__error,
.el-image__inner,
.el-image__placeholder {
  width: unset !important;
}
.signature-box {
  .el-tabs__item {
    font-size: 1.1em;
    &:hover {
      color: #000000;
    }
    &.is-active {
      color: #f754a2;
      background-color: #ffffff;
    }
  }
  .el-tabs__active-bar {
    background-color: #f754a2;
  }
  .el-tabs__nav-wrap::after {
    background-color: #ffffff;
  }
}
.el-dialog__body {
  padding: 10px 15px;
}
.el-dialog__footer {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 10px;
}
.avatar-uploader{
  border: 1px dashed #409eff;
  background-color: #f7f9ff;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
</style>